<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-overlay :show="energyLoading" class="h-100">
          <div
            class="card px-4 pt-4 h-100"
            style="background: linear-gradient(45.97deg, #f7f0c0, #fedaba)"
          >
            <div class="h-100">
              <h6 class="mb-0">Năng lượng hấp thụ trong ngày</h6>
            </div>
            <b-container fluid>
              <b-row>
                <b-col>
                  <div class="d-flex align-items-center h-100">
                    <apexchart
                      class="card-rounded-bottom donut"
                      :options="energyOptions"
                      :series="energySeries"
                      type="donut"
                    ></apexchart>
                  </div>
                </b-col>
                <b-col>
                  <div
                    v-for="(item, index) in absorbEnergy"
                    :key="index"
                    class="d-flex justify-content-between mb-6"
                  >
                    <div>
                      <img
                        :src="`/media/dashboard/Emoji/${item.image}.png`"
                        alt=""
                      />
                      <span class="ml-4">{{ item.name }}</span>
                    </div>
                    <span class="font-weight-bolder">{{ item.value }}</span>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4">
                  <img src="/media/dashboard/Emoji/Character.png" alt="" />
                </b-col>
                <b-col class="8">
                  <div class="d-flex align-items-center">
                    <p class="font-weight-bolder">
                      {{ noteEnergy }}
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </b-overlay>
      </b-col>
      <b-col>
        <b-overlay :show="carbLoading" class="h-100">
          <div
            class="card px-4 pt-4 h-100"
            style="background: linear-gradient(45.97deg, #f7f0c0, #fedaba)"
          >
            <div class="h-100">
              <h6 class="mb-0">Tinh bột hấp thụ trong ngày</h6>
            </div>
            <b-container fluid>
              <b-row>
                <b-col>
                  <div class="d-flex align-items-center h-100">
                    <apexchart
                      class="card-rounded-bottom donut"
                      :options="carbOptions"
                      :series="carbSeries"
                      type="donut"
                    ></apexchart>
                  </div>
                </b-col>
                <b-col>
                  <div
                    v-for="(item, index) in absorbCarb"
                    :key="index"
                    class="d-flex justify-content-between mb-6"
                  >
                    <div>
                      <img
                        :src="`/media/dashboard/Emoji/${item.image}.png`"
                        alt=""
                      />
                      <span class="ml-4">{{ item.name }}</span>
                    </div>
                    <span class="font-weight-bolder">{{ item.value }}</span>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4">
                  <img src="/media/dashboard/Emoji/Character2.png" alt="" />
                </b-col>
                <b-col class="8">
                  <b-row>
                    <b-col cols="3" class="pb-0">
                      <span class="font-weight-bolder"
                        >{{ percentCarb }}%
                      </span></b-col
                    >
                    <b-col cols="9" class="pb-0">
                      <b-progress :value="percentCarb" :max="100">
                        <b-progress-bar
                          :value="percentCarb"
                          style="background-color: #21a567"
                        >
                        </b-progress-bar>
                      </b-progress>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="">
                      <div class="d-flex align-items-center">
                        <p class="font-weight-bolder">
                          {{ noteCarb }}
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </b-overlay>
      </b-col>
      <b-col>
        <b-card class="card" no-body>
          <template #header>
            <div
              class="w-100 d-flex justify-content-between align-items-center"
            >
              <h6 class="mb-0">Tần suất phân bổ</h6>
              <div style="width: 170px">
                <v-select
                  transition=""
                  class=""
                  v-model="selectedDis"
                  :options="options"
                  :clearable="false"
                  label="name"
                ></v-select>
              </div>
            </div>
          </template>
          <b-overlay :show="distributionLoading">
            <div style="height: 300px; margin: auto">
              <v-chart :option="vdonutOptions" autoresize />
            </div>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-card class="card">
          <template #header>
            <div
              class="w-100 d-flex justify-content-between align-items-center"
            >
              <h6 class="mb-0">Dinh dưỡng đã nạp theo ngày</h6>
              <div style="width: 200px">
                <v-select
                  transition=""
                  class=""
                  v-model="selectedFoodTrend"
                  :options="options"
                  :clearable="false"
                  label="name"
                ></v-select>
              </div>
            </div>
          </template>
          <b-overlay :show="foodInDayLoading">
            <div class="w-100">
              <div style="height: 350px; margin: auto">
                <v-chart :option="stackColumnOptions" autoresize ref="line" />
              </div>
            </div>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card class="card">
          <template #header>
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="mb-0">Xu hướng dinh dưỡng</h6>
              <div style="width: 200px">
                <v-select
                  class="rounded-lg"
                  v-model="selectedTrend"
                  :options="options"
                  :clearable="false"
                  label="name"
                ></v-select>
              </div>
            </div>
          </template>
          <b-overlay :show="trendFoodLoading">
            <div class="w-100">
              <div style="height: 350px; margin: auto">
                <v-chart :option="trendLineOptions" autoresize ref="trend" />
              </div>
            </div>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import vselect from 'vue-select';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import {
  GridComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent,
} from 'echarts/components';
import { PieChart, LineChart, BarChart } from 'echarts/charts';
import VChart from 'vue-echarts';
use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent,
  GridComponent,
  LineChart,
  BarChart,
  PieChart,
  CanvasRenderer,
]);
export default {
  name: 'DietTab',
  components: { 'v-select': vselect, VChart },
  props: {
    userId: {
      type: String,
    },
    selectDay: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      energySeries: [],
      carbSeries: [],
      absorbCarb: [],
      absorbEnergy: [],
      selectedTrend: { name: 'Năng lượng', value: 'kcal' },
      selectedFoodTrend: { name: 'Năng lượng', value: 'kcal' },
      selectedDis: { name: 'Năng lượng', value: 'kcal' },
      options: [
        { name: 'Năng lượng', value: 'kcal' },
        { name: 'Tinh bột', value: 'carb' },
      ],
      carbOptions: {
        chart: {
          type: 'donut',
          height: 300,
        },
        labels: [],
        legend: {
          show: false,
        },
        silent: true,
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '12px',
                  show: false,
                },
                value: {
                  show: true,
                  fontSize: '12px',
                },
                total: {
                  show: true,
                  showAlways: true,
                  color: '#373d3f',
                  formatter: function (w) {
                    let [a, b] = w.globals.seriesTotals;
                    return `${a}
                            /${b}g`;
                  },
                },
              },
            },
          },
        },
        stroke: {
          width: 0,
        },
        colors: ['#21A567', '#f1f4ff'],
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: 'gradient',
        },
        tooltip: {
          enabled: false,
        },
      },
      energyOptions: {
        chart: {
          type: 'donut',
          height: 300,
        },
        silent: true,
        labels: [],
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: false,
                },
                value: {
                  show: true,
                  fontSize: '12px',
                },
                total: {
                  show: true,
                  showAlways: true,
                  formatter: function (w) {
                    let [a, b] = w.globals.seriesTotals;
                    return `${a}/
                    \n  ${b} kcal`;
                  },
                },
              },
            },
          },
        },
        stroke: {
          width: 0,
        },
        colors: ['#21A567', '#f1f4ff'],
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: 'gradient',
        },
        tooltip: {
          enabled: false,
        },
      },
      trendLineOptions: {
        dataZoom: {
          type: 'inside',
          start: 0,
        },
        xAxis: {
          type: 'category',
          data: [],
        },
        yAxis: {
          type: 'value',
        },
        tooltip: {
          trigger: 'axis',
        },
        itemStyle: {
          borderType: 'dotted',
        },

        series: [
          {
            data: [],

            type: 'line',
            symbolSize: 5,
            symbol: 'circle',
            lineStyle: {
              color: '#888C9F',
              width: 1,
            },
            markLine: {
              silent: true,
              lineStyle: {
                type: 'solid',
                color: '#21A567',
              },
              data: [],
            },
          },
        ],
      },

      stackColumnOptions: {
        dataZoom: {
          type: 'inside',
          start: 0,
        },
        legend: {
          show: true,
          bottom: 0,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          // Custom tooltip
          formatter: function (params) {
            let test = `<div style="min-width: 180px;">
            <div style="border-bottom: 1px solid #EBEDF3;">   ${
              params[0].axisValue
            } <br/>
            <div display: flex; justify-content: space-between;>
              <span style="font-weight: bold">Tổng</span>
              <span> ${params.reduce((a, b) => {
                return a + (b.value || 0);
              }, 0)} kcal</span> <br/>
            </div>`;
            for (let item = 0; item < params.length; item++) {
              test += `<div style="width: 100%;display:flex; justify-content: space-between;">
                 <span>${params[item].marker}${
                params[item].seriesName
              }</span><div>
                 <span style="color: #B5B5C3; font-size: 10px;">
                 (${params[item]?.data?.percentValue || 0}%)</span> ${
                params[item].value || 0
              } <span style="color: #B5B5C3">kcal</span></div>
               </div>`;
            }

            return test;
          },
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
        },
        series: [],
        barMaxWidth: 40,
      },

      vdonutOptions: {
        height: 350,
        label: {
          formatter: '{b}  {d}%  ',
        },
        labelLine: {
          smooth: 0.2,
          length: 15,
          length2: 220,
        },
        legend: {
          show: false,
          orient: 'vertical',
          left: 'left',
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '55%'],
            data: [],

            emphasis: {
              itemStyle: {
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      },
      noteCarb: null,
      noteEnergy: null,
      seriesEnergyChart: [],
      seriesCarbChart: [],
      seriesTrendCarbChart: [],
      seriesTrendEnergyChart: [],
      energySeriesByDay: [],
      carbSeriesByDay: [],
      targetEnergyChart: 0,
      targetCarbChart: 0,
      percentCarb: 0,
      searchParams: {
        currentDateTime: parseInt(new Date().getTime() / 1000),
        periodFilterType: this.selectDay,
        takeAll: true,
      },
      carbLoading: false,
      energyLoading: false,
      distributionLoading: false,
      foodInDayLoading: false,
      trendFoodLoading: false,
    };
  },
  watch: {
    selectDay: {
      handler(newVal) {
        this.searchParams.periodFilterType = newVal;
        this.loadData();
      },
    },
    'selectedDis.value': {
      handler(newVal) {
        if (newVal == 'kcal') {
          this.vdonutOptions.series[0].data = this.seriesEnergyChart;
        } else {
          this.vdonutOptions.series[0].data = this.seriesCarbChart;
        }
      },
    },
    selectedTrend: {
      deep: true,
      handler(newVal) {
        this.trendLineOptions.series[0].markLine.data = [];
        if (newVal.value == 'kcal') {
          this.trendLineOptions.series[0].data = this.seriesTrendEnergyChart;
          this.trendLineOptions.series[0].markLine.data = [
            {
              yAxis: this.targetEnergyChart,
            },
          ];
        } else {
          this.trendLineOptions.series[0].data = this.seriesTrendCarbChart;
          this.trendLineOptions.series[0].markLine.data = [
            {
              yAxis: this.targetCarbChart,
            },
          ];
        }
      },
    },
    selectedFoodTrend: {
      deep: true,
      handler(newVal) {
        if (newVal.value == 'kcal') {
          this.stackColumnOptions.series = this.energySeriesByDay;
        } else {
          this.stackColumnOptions.series = this.carbSeriesByDay;
        }
      },
    },
  },
  methods: {
    loadData() {
      this.loadTrend();
      this.loadDistribute();
      this.loadGetAbsorbCarb();
      this.loadGetAbsorbEnergy();
      this.loadDietByDay();
    },

    async loadTrend() {
      this.trendFoodLoading = true;
      try {
        let { data } = await this.$api.get('Admin/Diet/Statistic/trend', {
          params: { ...this.searchParams, patientId: this.patientId },
        });

        this.targetCarbChart = data.carbChart?.avgValue || 0;
        this.targetEnergyChart = data.energyChart?.avgValue || 0;
        let seriesTrendCarbChart = data.carbChart.items.map((e) => {
          this.trendLineOptions.xAxis.data.push(
            this.$moment(e.date * 1000).format('DD/MM/YYYY'),
          );
          return {
            itemStyle: { color: e.colorCode },
            value: e.value,
          };
        });
        let seriesTrendEnergyChart = data.energyChart.items.map((e) => ({
          itemStyle: { color: e.colorCode },
          value: e.value,
        }));
        this.seriesTrendCarbChart = seriesTrendCarbChart;
        this.seriesTrendEnergyChart = seriesTrendEnergyChart;
        this.selectedDis.value == 'kcal'
          ? ((this.trendLineOptions.series[0].data = seriesTrendEnergyChart),
            this.trendLineOptions.series[0].markLine.data.push({
              yAxis: this.targetEnergyChart,
            }))
          : ((this.trendLineOptions.series[0].data = seriesTrendCarbChart),
            this.trendLineOptions.series[0].markLine.data.push({
              yAxis: this.targetCarbChart,
            }));
        this.trendFoodLoading = false;
      } catch (error) {
        this.trendFoodLoading = false;
        this.$toastr.e(error, 'ERROR');
      }
    },

    async loadDistribute() {
      this.distributionLoading = true;
      try {
        let { data } = await this.$api.get('Admin/Diet/Statistic/distribute', {
          params: { ...this.searchParams, patientId: this.patientId },
        });
        let seriesCarbChart = data.carbChart.details.map((e) => ({
          itemStyle: { color: e.colorCode },
          name: e.text,
          value: e.percentValue,
        }));
        let seriesEnergyChart = data.energyChart.details.map((e) => ({
          itemStyle: { color: e.colorCode },
          name: e.text,
          value: e.percentValue,
        }));
        this.seriesCarbChart = seriesCarbChart;
        this.seriesEnergyChart = seriesEnergyChart;
        this.selectedDis.value == 'kcal'
          ? (this.vdonutOptions.series[0].data = seriesEnergyChart)
          : (this.vdonutOptions.series[0].data = seriesCarbChart);
        this.distributionLoading = false;
      } catch (error) {
        this.distributionLoading = false;
        this.$toastr.e(error, 'ERROR');
      }
    },

    async loadGetAbsorbCarb() {
      this.carbLoading = true;
      try {
        const { data } = await this.$api.get('Admin/Diet/Statistic/carb', {
          params: { ...this.searchParams, patientId: this.patientId },
        });
        this.absorbCarb = data.mealDetails.map((e) => ({
          name: e.text,
          image: e.icon?.url,
          value: e.value,
        }));
        this.carbSeries = [data.total || 0, data.goal || 0];
        this.percentCarb = data.percent;
        this.noteCarb = data.note;
        this.carbLoading = false;
      } catch (error) {
        this.carbLoading = false;
        this.$toastr.e(error, 'ERROR');
      }
    },
    async loadGetAbsorbEnergy() {
      this.energyLoading = true;
      try {
        const { data } = await this.$api.get('Admin/Diet/Statistic/calo', {
          params: { ...this.searchParams, patientId: this.patientId },
        });
        this.absorbEnergy = data.mealDetails.map((e) => ({
          name: e.text,
          image: e.icon?.url,
          value: e.value,
        }));
        this.energySeries = [data.total || 0, data.goal || 0];
        this.noteEnergy = data.note;
        this.energyLoading = false;
      } catch (error) {
        this.energyLoading = false;
        this.$toastr.e(error, 'ERROR');
      }
    },

    async loadDietByDay() {
      this.foodInDayLoading = true;
      this.trendLineOptions.xAxis.data = [];
      this.trendLineOptions.series[0].markLine.data = [];
      try {
        const { data } = await this.$api.get('Admin/Diet/Statistic/detail', {
          params: {
            ...this.searchParams,
            patientId: this.patientId,
          },
        });
        let selectTypeEnergy = this.selectedFoodTrend.value === 'kcal';
        const { carbChart, legends, energyChart } = data;

        let energy = [];
        for (const e of energyChart.items) {
          energy.push({
            date: this.$moment(e.date * 1000).format('DD/MM/YYYY'),
            total: e.value,
            details: e.details,
            color: e.details.map((el) => el.colorCode),
          });
        }
        let carb = [];
        for (const e of carbChart.items) {
          carb.push({
            date: this.$moment(e.date * 1000).format('DD/MM/YYYY'),
            total: e.value,
            details: e.details,
            color: e.details.map((el) => el.colorCode),
          });
        }

        const carbSeries = legends.map((e) => ({
          name: e.text,
          type: 'bar',
          stack: 'total',
          emphasis: {
            focus: 'series',
          },
          itemStyle: {
            color: e.colorCode,
          },
          data: carb.map((en) =>
            en.details.find((color) => color.colorCode === e.colorCode),
          ),
        }));
        const energySeries = legends.map((e) => ({
          name: e.text,
          type: 'bar',
          stack: 'total',
          emphasis: {
            focus: 'series',
          },
          itemStyle: {
            color: e.colorCode,
          },
          data: energy.map((en) =>
            en.details.find((color) => color.colorCode === e.colorCode),
          ),
        }));
        this.carbSeriesByDay = carbSeries;
        this.energySeriesByDay = energySeries;
        this.stackColumnOptions.series = selectTypeEnergy
          ? energySeries
          : carbSeries;
        this.stackColumnOptions.xAxis.data = selectTypeEnergy
          ? energy.map((e) => e.date)
          : carb.map((e) => e.date);
        this.foodInDayLoading = false;
      } catch (error) {
        this.foodInDayLoading = false;
        this.$toastr.e(error, 'ERROR');
      }
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    patientId() {
      return this.userId;
    },
  },
};
</script>

<style lang="scss" scoped>
.exercises {
  border-radius: 6px;
  border: 1px solid #ebedf3;
  &__border {
    &-left {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &-right {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
.donut {
  max-width: 170px;
  min-width: 170px;
}
.text-xl {
  font-size: 1.2rem;
}
</style>
